<template>
  <section>
    <form v-if="!csv">
      <h2>CSV Tabelle einlesen</h2>
      <input ref="myFile" type="file" @change="setData" />
    </form>
    <div v-else>
      <h2 v-if="!copy">Nachfolgenden Code anklicken für Copy &amp; Paste</h2>
      <div v-if="copy" class="alert">in Zwischenablage kopiert</div>
      <textarea v-else v-model="csv" @click="copyToClipboard" />
    </div>
  </section>
</template>
<script>
import csv2html from '@/plugins/csv2html.js'

export default {
  components: {},
  props: {},
  data () {
    return {
      text: '',
      csv: '',
      copy: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    copyToClipboard (event) {
      event.target.focus()
      event.target.select()
      document.execCommand('copy')
      document.getSelection().removeAllRanges()
      this.copy = true
      setTimeout(function () {
        this.copy = false
      }.bind(this), 2000)
    },
    setData () {
      let file = this.$refs.myFile.files[0];
      if(!file || file.type !== 'text/csv')
        return;

      let reader = new FileReader();
       reader.readAsText(file, "UTF-8");
       reader.onload =  evt => {
         this.csv = evt.target.result;
         this.setOutput()
       }
    },
    setOutput () {
      let c2h = new csv2html(this.csv)
      this.csv = c2h.output
    }
  }
}
</script>
<style scoped>
  section {
    width: 100%;
    flex: 1;
    text-align: center;
  }
  textarea {
    width: 100%;
    flex: 1;
    height: 50vh;
    border: 0;
    padding: 10px;
    background-color: #ddd;
    box-sizing: border-box;
    overflow: hidden;
  }
  textarea:hover, textarea:focus {
    outline: none;
    border: 0;
    cursor: pointer;
  }
  .alert, h2 {
    font-size: 3rem;
  }
  .alert {
    color: green;
  }
</style>
