import bodyRow from './bodyRow.js'

const csv2html = function (data) {
  data = data.replace('        ', '')
  this.output = ''
  this.splitter = ';'

  if(!data.includes(this.splitter)) {
    this.splitter = ','
  }

  data = data.split("\n")

  for(let i = 1; i < data.length - 1; i++) {
    let row = data[i]
    row = row.split(this.splitter)
    const col = bodyRow
    this.output += col
                    .split('%%link%%').join('https://' + row[0].split('"').join(''))
                    .split('%%frauen%%').join(row[1].split('"').join(''))
                    .split('%%männer%%').join(row[2].split('"').join(''))
                    .split('%%kinder%%').join(row[3].split('"').join(''))
                    .split('%%bestager%%').join(row[4].split('"').join(''))
                    .split('%%komparse%%').join(row[5].split('"').join(''))
  }


}

export default csv2html;
